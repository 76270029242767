.btn {
	background-color: $color-lt-lt-gray;
	border-color: $color-gray;
	border-radius: $default-border-radius;
	border-width: 1px;
	box-shadow: inset 0 1px 1px rgba(255, 255, 255, .5);
	font-size: $default-font-size;
	padding: 2px 8px;
	transition: background-color .2s, border .2s, box-shadow .2s, color .2s;
	
	&:hover,
	&:active {
		background-color: $color-almost-white !important;
		border-color: $color-gray !important;
		border-width: 1px;
	}
	&:active {
		box-shadow: inset 0 1px 2px rgba(0, 0, 0, .2);
	}
	&.icon-inline {
		background-position: 8px center;
		background-repeat: no-repeat;
		padding-left: 28px;
		
		&.attach-add {
			background-image: url(/images/btn-icon--attach-add.svg);
		}
		&.attach-has {
			background-image: url(/images/btn-icon--attach-has.svg);
		}
		&.copy {
			background-image: url(/images/btn-icon--copy.svg);
		}
		&.delete {
			background-image: url(/images/btn-icon--delete.svg);
		}
		&.edit {
			background-image: url(/images/btn-icon--edit.svg);
		}
		&.new {
			background-image: url(/images/btn-icon--new.svg);
		}
		&.print {
			background-image: url(/images/btn-icon--print.svg);
		}
		&.save {
			background-image: url(/images/btn-icon--save.svg);
		}
		&.saved {
			background-image: url(/images/btn-icon--saved.svg);
		}
		&.search {
			background-image: url(/images/btn-icon--search.svg);
		}
		&.submit {
			background-image: url(/images/btn-icon--submit.svg);
		}
	}
}
.sign-in .btn {
	font-size: $font-size-lg;
	padding: 7px 12px;
}
