// colors - uh primary
$color-uh-red: #c8102e;
$color-uh-teal: #00b388;
$color-uh-gold: #f6be00;
$color-uh-gray: #888b8d;
$color-uh-cream: #fff9d9;
$color-uh-white: #fff;

// colors - uh secondary
$color-uh-brick: #960c22;
$color-uh-green: #00866c;
$color-uh-mustard: #d89b00;
$color-uh-slate: #54585a;

// colors - uh tertiary
$color-uh-chocolate: #640817;
$color-uh-forest: #005950;
$color-uh-ocher: #b97800;
$color-uh-black: #000;

// colors - grays
$color-white: #fff;
$color-almost-white: #f9f9f9;
$color-lt-lt-gray: #eff0f0;
$color-lt-gray: #e9e9e9;
$color-gray: #c9cacb;
$color-md-gray: #888b8d;
$color-dk-gray: #54585a;
$color-black: #000;

// typography
$default-font-color: #333;
