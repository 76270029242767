a {
	color: $link-color;
	position: relative;
	transition: background-color .1s, border .2s, color .2s;
	z-index: 10;

	&:hover,
	&:focus {
		cursor: pointer;
	}
	&.no-deco {

		&:hover,
		&:focus {
			color: $link-color-hover;
			text-decoration: none;
		}
	}
}
@media print {
  a[href]:after {
    content: none !important;
  }
}
body {
	background-color: $color-almost-white;
	line-height: 1.4em;
}
hr {
	border-color: $color-lt-gray;
	margin-bottom: 1em;
	margin-top: 1em;
}
.block-center--hori {
	@include block-center--hori;
}
.clear-left {
	clear: left !important;
}
.clear-right {
	clear: right !important;
}
.container {
	@include clearfix;
	position: relative;
}
.no-margin--bottom {
	margin-bottom: 0;
}
.no-margin--all {
	margin: 0 !important;
}
.no-padding--bottom {
	padding-bottom: 0 !important;
}
.no-padding--top {
	padding-top: 0 !important;
}
.no-padding--all {
	padding: 0 !important;
}
