@media print {
	.container {
		width: 100%;
	}
}
html {
}
body {
	
	&.sign-in {
		
		.mynsm-logo,
		.nsm-logo {
			max-width: 264px;
			width: 90%
		}
		.mynsm-logo {
			margin-bottom: 2em;
		}
		.nsm-logo {
			margin-top: 2em;
		}
	}
}
main {
	margin-top: 60px;
	
	@media (min-width: 768px) {
		margin-top: 40px;
	}
}
.dashboard main {
	margin-top: 4em;
	
	@media (min-width: 768px) {
		margin-top: 3em;
	}
}
.sign-in main {
	margin-top: 3em;
}
.alert {
	border-radius: $default-border-radius;
}
.apps {
	
	.app {
		color: $color-dk-gray;
		display: inline-block;
		line-height: 1em;
		margin-left: -.125em;
		margin-right: -.125em;
		padding: 0 1em 1em;
		vertical-align: top;
		width: 50%;
		
		// @media (min-width: 576px) {
		// 	width: 25%;
		// }
		@media (min-width: 768px) {
			width: 140px;
		}
		@media (min-width: 1024px) {
			width: 160px;
		}
		
		.app-icon {
			background-color: $color-lt-lt-gray;
			background-position: center center;
			background-repeat: no-repeat;
			background-size: 65%;
			border: 1px solid $color-gray;
			border-radius: 6px;
			display: block;
			height: 108px;
			margin-bottom: 5px;
			padding: 10px;
			transition: background-color .2s;
			width: 108px;
			@include block-center--hori;
			
			@media (min-width: 414px) {
				// height: 80px;
				// width: 80px;
			}
			@media (min-width: 768px) {
				height: 90px;
				width: 90px;
			}
		}
		.app-beta {
			color: $color-uh-mustard;
			display: block;
			font-size: 85%;
		}
		.app-label {
			@include font-size--small;
		}
		&:hover {
			text-decoration: none;
			
			.app-icon {
				background-color: $color-almost-white;
				animation-name: app-button;
				animation-delay: .05s;
				animation-direction: alternate;
				animation-duration: .5s;
				animation-iteration-count: infinite;
			}
		}
	}
}
.panel {
	border-radius: $default-border-radius;
	box-shadow: none;
	overflow: auto;
	
	h3 {
		margin: 0;
	}
	p {
		margin: 0;
	}
}
.panel-heading {
	border-radius: 0;
}
.pagination {
	
	& > li > a,
	& > li > span {
		border-color: $color-gray;
		line-height: inherit;
		padding: 5px 10px;
	}
	& > li:first-child > a,
	& > li:first-child > span {
		border-top-left-radius: $default-border-radius;
		border-bottom-left-radius: $default-border-radius;	
	}
	& > li:last-child > a,
	& > li:last-child > span {
		border-top-right-radius: $default-border-radius;
		border-bottom-right-radius: $default-border-radius;	
	}
	& > .active > a {
		background-color: $color-gray;
		border-color: $color-gray;
	}
	& > .disabled > a {
		border-color: $color-gray;
	}
}
.particles {
	height: 99%;
	position: absolute;
	width: 100%;
	z-index: -100;
}
