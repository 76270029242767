.navbar {
	min-height: inherit;
}
.navbar-brand {
	height: inherit;
	
	@media (min-width: 768px) {
		padding-bottom: 8px;
		padding-top: 8px;
	}
}
.nav {
	font-size: $font-size-lg;
		
	& > li {
		
		& > a {
			color: #fff !important;
			
			@media (min-width: 768px) {
				@include font-size--small;
				line-height: 1em;
				padding: 11px 15px 9px;
				// padding: 13px 15px 7px; (aligned with bottom of logo)
			}
		}
	}
	.icon-inline {
		padding-top: 9px;
		// padding-top: 11px; (aligned with bottom of logo)
		
		&:before {
			background-image: url(/images/head-icons-sprite.svg);
			background-position: left bottom;
			background-repeat: no-repeat;
			content: "";
			display: inline-block;
			height: 16px;
			margin-right: 6px;
			margin-bottom: -4px;
			width: 16px;
		}
		&.help:before {
			background-position: -16px center;
		}
		&.user:before {
			background-position: left center;
		}
		&.utilities:before {
			background-position: -32px center;
		}
	}
	.dropdown:hover .dropdown-menu {
		@media (min-width: 768px) {
			display: block;
		}
	}
	.dropdown-menu {
		border-radius: $default-border-radius;
		border-top: none;
		overflow: auto;
		
		a {
			color: #fff !important;
			padding: 5px 10px;
			
			@media (min-width: 768px) {
				color: $color-dk-gray !important;
			}
		}
		.icon-inline:before {
			background-image: url(/images/head-icons-menu-sprite.svg);
		}
		.icon-inline.account:before {
			background-position: left center;
		}
		.icon-inline.analytics:before {
			background-position: -96px center;
		}
		.icon-inline.bug-report:before {
			background-position: -48px center;
		}
		.icon-inline.feature-req:before {
			background-position: -32px center;
		}
		.icon-inline.live-chat:before {
			background-position: -112px center;
		}
		.icon-inline.logmein:before {
			background-position: -128px center;
		}
		.icon-inline.manuals:before {
			background-position: -80px center;
		}
		.icon-inline.pingdom:before {
			background-position: -144px center;
		}
		.icon-inline.sign-out:before {
			background-position: -16px center;
		}
		.icon-inline.sys-status:before {
			background-position: -64px center;
		}
	}
}
.dashboard .navbar {
	background-color: $color-uh-red;
}
.app-finance .navbar {
	background-color: #06f;
}
.dashboard .navbar-default .navbar-nav > .dropdown > a:hover,
.dashboard .navbar-default .navbar-nav > .open > a {
	background-color: $color-uh-brick;
	
	&:hover,
	&:focus {
		background-color: $color-uh-brick;
	}
}
.app-finance .navbar-default .navbar-nav > .dropdown > a:hover,
.app-finance .navbar-default .navbar-nav > .open > a {
	background-color: darken(#06f, 15%);
	
	&:hover,
	&:focus {
		background-color: darken(#06f, 15%);
	}
}
.navbar-default .navbar-toggle .icon-bar {
	background-color: #fff;
}
