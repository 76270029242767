// tags
blockquote {
	border-left-color: $color-lt-gray;
}
body {
	font-size: $default-font-size;
	
	&.dashboard {
		font-size: $font-size-lg;
	}
	&.sign-in {
		font-size: $font-size-xl;
	}
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin-top: 0;
}
h1,
h2,
h3 {
	color: $color-dk-gray;
	font-weight: 200;
	line-height: 1.2em;
}
h1 {
	font-size: 34px;
	@media (min-width: 768px) {
		font-size: 43px;
	}
}
h2 .small {
	font-weight: 200;
}
h4 {
	font-weight: normal;
}
li {
	margin-bottom: .25em;
}
p {
	line-height: inherit;
}
ul {
	margin-top: 0;
}

// classes
.block {
	margin-left: auto;
	margin-right: auto;
}
.lead {
	color: $color-dk-gray;
	font-size: 1.3em;
	line-height: 1.2em;
	@media (min-width: 768px) {
		font-size: 1.5em;
	}
}
small, .small {
	line-height: 1.4em;
}

// classes - colors
.color-gray {
	color: $color-md-gray;
}
.color-red {
	color: $color-uh-red;
}
