td,
th,
tr {
	font-size: 12px !important;
	transition: background-color .1s;
}
.table {
	border: 1px solid $color-gray;
	
	& > thead > tr > th {
		border-bottom-width: 2px;
	}
}
.table-hover {
	
	& > tbody > tr:hover {
		background-color: $color-uh-cream !important;
	}
}
.table-striped {
	
	& > tbody > tr:nth-of-type(odd) {
		background-color: #fff;
	}
}
