// colors
$link-color: $color-uh-red;
$link-color-hover: mix($link-color, #fff, 70%);

// misc
$default-border-radius: 2px;

// typography
$default-font-size: 14px;
$font-size-lg: 16px;
$font-size-xl: 18px;
$text-indent-hide: -9999px;
