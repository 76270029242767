table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > th:first-child:before {
	background-color: #06f;
	border-radius: 50%;
	border-width: 1px;
	box-shadow: none;
	font-family: inherit;
	font-size: 16px;
	height: 16px;
	top: 4px;
	width: 16px;
}
