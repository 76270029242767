input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="text"],
select,
textarea {
	border: 1px solid $color-gray;
	border-radius: $default-border-radius;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
	@include font-size--small;
	line-height: 1em;
	padding: 4px;
}
.form-control {
	height: inherit;
}
.form-group {
	margin-bottom: 10px;
}
label {
	margin-bottom: 0;
}
.form--sign-in {
	
	.form-group {
		margin-bottom: 15px;
	}
	input[type="email"],
	input[type="number"],
	input[type="password"],
	input[type="text"] {
		padding: 10px;	
	}
	p {
		margin-top: 1em;
	}
	@media (min-width: 768px) {
		width: 260px;
	}
}
